@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

$color1: #212638;
$color2: #014E95;
$color3: #fff;
$color4: #ddd;
$color5: #232D47;
$color6:#000;
/*-section1*/@at-root
body{
    width: 100%;
    height: 100vh;
    background-color: $color1;
}
.wrapper{
  display: flex;
  justify-content: center;
  
     .dynamic-txts{
      height: 90px;
      line-height: 90px;
      overflow: hidden;
     
      li{
          list-style: none;
          color: $color2;
          font-size: 35px;
          font-weight: 700;
          position: relative;
          top: 0;
          animation: slide 12s steps(4) infinite;
        }
        span{
          position: relative;
          margin: 5px 0;
          line-height: 80px;
        }
    }
  }
    @keyframes slide {
      100%{
        top: -360px;
      }
    }
    .dynamic-txts li span::after{
      content: "";
      position: absolute;
      left: 0;
      height: 20vh;
      width: 100%;
      background: $color1;
      border-left: 2px solid $color1;
      animation: typing 15s steps(10) infinite;
    }
    @keyframes typing {
      40%, 60%{
        left: calc(100% + 30px);
      }
      100%{
        left: 0;
      }
    }
/*--  section2--*/
.sec2{
  .imgpic1{
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 70px;
      border: 3px $color4 solid;
} 
   .sa{
       color: $color4;
       font-size: 40px;
       display: flex;
       justify-content: center;
   }
   .al{
       color: $color2;
   }
   .hi{
       text-align: center;
       font-size: 25px;
       color: $color3;
   }
}

.sec3{
  position: fixed;
  top: 15rem;
  .footer-socials ul{
      padding: 0;
      margin: 0;
      justify-content: center;
      
      margin: 5px;
   li {
      list-style: none;
      margin: 0 10px;
      }
   .fa{
      color: $color6;
      font-size: 30px;
       line-height: 50px;
      transition: .5s;
      }
  .fa:hover {
      color: $color3;
      }
   a {
          position: relative;
          display: block;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: $color1;
          text-align: center;
          transition: 0.5s;
          transform: translate(0,0px);
          box-shadow: 0px 7px 5px $color1;
      }
      a:hover {
          transform: rotate(0deg) skew(0deg) translate(0, -10px);
      }
      :nth-child(1) a:hover {
          background-color: $color1;
      }
      :nth-child(2) a:hover {
          background-color: $color1;
      }
      :nth-child(3) a:hover {
          background-color: $color1;
      }
  }
}

.sec4{
  margin-left: 20%;
  margin-right: 20%;
  display: grid;
  grid-template-columns: repeat(1fr,1fr);

    a{
      text-decoration: none;
    }
  .wb{
    text-align: center;
    color: $color3;
    text-decoration: none;
  }
  .un{
    display: flex;
    align-items: center;
    color: $color3;
    background: $color5;
    border-radius: 50px;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    height: 7vh;
  }
  .un:hover{
    transform: rotate(0deg) skew(0deg) translate(0, -10px);
    transition: 0.9s ease-in-out;
    color: $color2;
    background-color: $color4;
  }
 
  .imgpor{
    height: 5vh;
  }
 
}

footer{
  display: flex;
  justify-content: center;
  .dd{
    color: $color4;
  }
}
//logoComponents
.sam{
  position: fixed;
  display: flex;
  top: 5%;
  font-size: 30px;
  color: $color4;
  left: 10%;
  background-color: $color1;
  width: 100%;
  height: 6vh;
  border-radius: 10px;
}
.GHR{
  color: $color4;
}

//portfolio
.sayhi{
  text-align: center;
  position: absolute;
  top: 20%;
  left: 45%;
  color: $color4;
  cursor: pointer;
  text-decoration: none;
}
.work{
  text-align: center;
  position: absolute;
  top: 35%;
  left: 45%;
  color: $color4;
  cursor: pointer;
  text-decoration: none;
}
.about{
  text-align: center;
  position: absolute;
  top: 50%;
  left: 45%;
  color: $color4;
  cursor: pointer;
  text-decoration: none;
}
.skills{
  text-align: center;
  position: absolute;
  top: 65%;
  left: 45%;
  color: $color4;
  cursor: pointer;
  text-decoration: none;
}
.navbrr{
  background-color: $color2;
}
/*ork*/
/*.wk{
font-size: 50px;
position: fixed;
top: 50%;
left: 35%;

}*/
.ork .content{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  position: relative;
  padding: 70px;
  top: 20px;
  grid-gap: 30px;
  h1{
    text-align: center;
  }
}
.ork img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.ork .box{
  background-color: $color5;
  border-radius: 10px;
  position: relative;
  color: $color2;
  height: 100%;
  h2{
  color: $color2;
}
  .icon{
    background-color: none;
    border-radius: 10px;
    .vis{
  text-decoration: none;
 color: $color2;
 font-family: cursive;
 font-size: 15px;
}
  }
}
 
 
 
.overlay{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -50%;
  opacity: 0;
  border-radius: 10px;
}
.ork .box:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
.ork .box::after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  opacity: 0;
  transition: 0.5s;
  border-radius: 10px;
}
.ork .box:nth-child(4)::after,
.ork .box:nth-child(3)::after,
.ork .box:nth-child(2)::after,
.ork .box:nth-child(5)::after{
  width: 100%;
  height: 100%;
  background-color: rgba(15, 18, 20, 0.925);
}
.ork .box:nth-child(6)::after,
.ork .box:nth-child(1)::after{
  width: 100%;
  height: 100%;
  background-color: rgba(19, 21, 22, 0.925);
}
.ork .box:hover::after{
  opacity: 1;
}
.ork .box .icon,
.ork .box .text{
  position: relative;
  top: 10%;
  z-index: 4;
  text-align: center;
}

@media (min-width:500px) and (max-width:900px){
  .ork .content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    padding: 70px;
    top: 20px;
    grid-gap: 30px;
  }
}
@media (min-width:900px){
  .ork .content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    padding: 80px;
    top: 20px;
    grid-gap: 30px;
  }
}
.grr{
  position: relative;
  left: 30%;
}
/*----*/

